/* eslint-disable */
(function($) {
	$.fn.correctPopupPosition = function (name) {
		var self = this,
			tmpWidth,
			$body = $('body'),
			$html = $('html'),
			currentScrollPosition,
			correctPosition = function(force) {
				var $dialog = $(self);
				if(typeof $dialog.dialog('instance') !== 'undefined'){
					var dialogHeight = $dialog.parents('.ui-dialog').height(),
						windowHeight = $(window).height(),
						windowWidth = $(window).width(),
						position = dialogHeight > windowHeight ? "center top" : "center center",
						currentDialogPosition = $dialog.dialog("option", "position");
					if(currentDialogPosition.my !== position || currentDialogPosition.at !== position
					|| tmpWidth !== windowWidth || (tmpWidth === windowWidth && position == "center center") || force) {
					$('.ui-widget-overlay').height(windowHeight);
					$('.ui-widget-overlay').scrollTop(0);
						$dialog.dialog("option", "position" ,	{
							my: position,
							at: position,
							of: $('.ui-widget-overlay')
						});
					}
					tmpWidth = windowWidth;
				} else {
					$(window).off('resize', self.resizeFunction);
				}
			},
			$overlay = null;
		this.resizeFunction = function(){
			if($(self).is(':visible')) {
				correctPosition();
			}
		};
		if($(this).length) {
			if(!name) {
				$(window).on('resize', self.resizeFunction);
				$(this).on("dialogopen", function( event, ui ) {
					var $dialog = $(self).parents('.ui-dialog').detach();
					$('.ui-widget-overlay').hide();
					$overlay = $('.ui-widget-overlay.custom');
					
					if(!$overlay || !$overlay.length) {
						$overlay = $('<div class="ui-widget-overlay custom"></div>');
						$body.append($overlay);
					} else {
						$overlay.show();
					}
					currentScrollPosition = $(document).scrollTop();
					$html.addClass('noScroll');
					$overlay.append($dialog);
					correctPosition(true);
					setTimeout(function() {
						correctPosition(true);
					}, 800);
				});
				$(this).on("dialogbeforeclose", function( event, ui ) {
					var $dialog = $(self).parents('.ui-dialog').detach();
					$body.append($dialog);
					$html.addClass('noScrollClose');
					$(document).scrollTop(currentScrollPosition);
					$('.ui-widget-overlay').show();
					$overlay.hide();
				});

				$(this).on("dialogclose", function( event, ui ) {
					$html.removeClass('noScroll noScrollClose');
					$overlay.hide();
				});
			} else {
				if(name == 'correct') {
					correctPosition(true);
				}
			}
		}

		return this;
	};
} (jQuery));