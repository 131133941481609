/* eslint-disable */
jQuery.datepicker.regional[ "ru" ] = {
	closeText: 'Закрыть',
	prevText: '&#xf289;',
	nextText: '&#xf269;',
	currentText: 'Сегодня',
	monthNames: ['Январь','Февраль','Март','Апрель','Май','Июнь',
		'Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
	monthNamesShort: ['Янв','Фев','Мар','Апр','Май','Июн',
		'Июл','Авг','Сен','Окт','Ноя','Дек'],
	dayNames: ['воскресенье','понедельник','вторник','среда','четверг','пятница','суббота'],
	dayNamesShort: ['вс','пн','вт','ср','чт','пт','сб'],
	dayNamesMin: ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
	dateFormat: 'dd.mm.yy',
	firstDay: 1,
	isRTL: false
};

$.datepicker.setDefaults( jQuery.datepicker.regional[ "ru" ] );